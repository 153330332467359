import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import Sidebar from './sidebar';
import ContentRenderer from './ContentRenderer';

import { sidebarMenu } from './constant';
import { getNextStepInfo, getPrevStepInfo } from './ratePlanHelper';
import { resetError } from '../../redux/actions/experiences/activities';
import { addRatePlan, editRatePlan, deleteRatePlan } from '../../redux/actions/rates/ratePlans';

const styles = theme =>({
    dialog:{
        position: 'relative'
    },
    container: {
        display: 'flex',
        height: '100%',
        position: 'relative'
    },
    sidebarContainer:{
        backgroundColor: '#F8F9FB'
    },
    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
      },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%',
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 16,
        zIndex: 99,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        },
    },
});

class RatePlanFLow extends Component {

    constructor(props){
        super(props);
        const defaultMainStepID = Object.keys(sidebarMenu)[0];
        this.state = {
            activeMenuID: defaultMainStepID,
            video: false,
            ratePlan: {
                isDefault: false,
                isDeleted: false,
                publicName: '', 
                roomTypeIDs: [],
                privateName: '', 
                description: '',
                visibility: [],
                multiCurrency: {
                    currency: '',
                    conversionRate: 0,
                    enabled: false
                }
            }
        }
    }

    componentDidMount(){
        if (this.props.ratePlanID) {
            const rawRatePlan = this.props.ratePlans[this.props.ratePlanID];
            const processedRatePlan = {
                _id: rawRatePlan._id,
                isDefault: rawRatePlan.isDefault,
                isDeleted: rawRatePlan.isDeleted,
                publicName: rawRatePlan. publicName,
                privateName: rawRatePlan.privateName,
                propertyID: rawRatePlan.propertyID,
                isDerived: rawRatePlan.isDerived,
            }

            if (rawRatePlan.hasOwnProperty('description')) processedRatePlan.description = rawRatePlan.description
            if (rawRatePlan.hasOwnProperty('roomTypeIDs')) processedRatePlan.roomTypeIDs = rawRatePlan.roomTypeIDs
            if (rawRatePlan.hasOwnProperty('derivedRate')) processedRatePlan.derivedRate = rawRatePlan.derivedRate
            if (rawRatePlan.hasOwnProperty('minLOS')) processedRatePlan.minLOS = rawRatePlan.minLOS
            if (rawRatePlan.hasOwnProperty('nonRefundable')) processedRatePlan.nonRefundable = rawRatePlan.nonRefundable
            if (rawRatePlan.hasOwnProperty('nrPolicy')) processedRatePlan.nrPolicy = rawRatePlan.nrPolicy
            if (rawRatePlan.hasOwnProperty('cancellationPolicy')) processedRatePlan.cancellationPolicy = rawRatePlan.cancellationPolicy
            if (rawRatePlan.hasOwnProperty('visibility')) processedRatePlan.visibility = rawRatePlan.visibility
            if (rawRatePlan.hasOwnProperty('roomTypeLinks')) processedRatePlan.roomTypeLinks = rawRatePlan.roomTypeLinks
            if (rawRatePlan.hasOwnProperty('multiCurrency')) processedRatePlan.multiCurrency = rawRatePlan.multiCurrency

            this.setState({
                ...this.state, 
                ratePlan: processedRatePlan
            })
        }
    }

    handleUpdateRatePlan = (key, value)  => {
        if (key=='isDerived') {
            if (value===true) {
                this.setState({
                    ...this.state, 
                    ratePlan: {...this.state.ratePlan, [key]: value, derivedRate: {percentOrAmount: 'amount', reference: '', value: ''}}
                })
            } else if (value===false) {
                let editRatePlan = this.state.ratePlan;
                delete editRatePlan.derivedRate;
                this.setState({...this.state, ratePlan: {...editRatePlan, isDerived: false}})    
            }
        } else if (key=='nonRefundable') {
            if (value===false) {
                this.setState({
                    ...this.state, 
                    ratePlan: {...this.state.ratePlan, [key]: value, cancellationPolicy: {duration: '', conditions: '', percentChargeable: ''}}
                })
            } else {
                let editRatePlan = this.state.ratePlan;
                delete editRatePlan.cancellationPolicy;
                this.setState({...this.state, ratePlan: {...editRatePlan, nonRefundable: true }})    
            }
        }
        else {
            this.setState({...this.state, ratePlan: {...this.state.ratePlan, [key]: value}})
        }
    }

    navigateToMenuHandler = (menuID) =>{
        this.setState({ activeMenuID: menuID });
    }

    nextStepHandler = () =>{
        const { activeMenuID } = this.state;
        const nextStepInfo = getNextStepInfo(activeMenuID);

        if(nextStepInfo) this.navigateToMenuHandler(nextStepInfo.menuID);
    }

    prevStepHandler = () =>{
        const { activeMenuID } = this.state;
        const prevStepInfo = getPrevStepInfo(activeMenuID);

        if(prevStepInfo) this.navigateToMenuHandler(prevStepInfo.menuID);
    }

    submitHandler = () => {
        console.log('submit', this.state.ratePlan, this.props.currentSpace.propertyID);
        if (this.state.ratePlan._id) {
            const response = this.props.dispatch(editRatePlan({...this.state.ratePlan, propertyID: this.props.currentSpace.propertyID}))
            response.then(response =>{
                if(response){
                    this.props.handleClose();
                }
            });
            // Google tag manager
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Rates', 'eventAction': 'edit', 'eventLabel': 'Edit Rate Plan'});
        } else {
            const response = this.props.dispatch(addRatePlan({...this.state.ratePlan, propertyID: this.props.currentSpace.propertyID}))
            response.then(response =>{
                if(response){
                    this.props.handleClose();
                }
            });
            // Google tag manager
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Rates', 'eventAction': 'create', 'eventLabel': 'Add Rate Plan'});
        }
    }

    handleDeleteRatePlan = () => {
        const response = this.props.dispatch(deleteRatePlan(this.state.ratePlan._id))
        response.then(response =>{
            if(response){
                this.props.handleClose();
            }
        });
    }

    handleCloseError = () =>{
        const { dispatch } = this.props;
        dispatch(resetError('ADD_RATE_PLAN'));
        dispatch(resetError('EDIT_RATE_PLAN'));
        dispatch(resetError('DELETE_RATEPLAN'));
    }

    render(){
        const { 
            classes, 
            closeModalHandler,
            currentSpace,
            isLoading,
            errors 
        } = this.props;

        const { activeMenuID } = this.state;

        const contentProps = {
            activeStepInfo: {
                menuID: activeMenuID
            },
            currency: currentSpace.currency,
            nextStepHandler: this.nextStepHandler,
            prevStepHandler: this.prevStepHandler,
            ratePlan: this.state.ratePlan,
            handleUpdateRatePlan: this.handleUpdateRatePlan,
            submitHandler: this.submitHandler
        }

        return(
            <Dialog 
                open={this.props.open} 
                onClose={this.props.handleClose}
                className={classes.dialog} 
                fullScreen={true}
            >   
                <CloseIcon 
                    className={classes.closeIcon} 
                    onClick={this.props.handleClose} 
                />

                <Grid container className={classes.container}>

                    {/*----------------PROGRESS LOADER--------------------- */}
                    {isLoading && (
                        <div className={classes.loadingState}>
                            <CircularProgress className={classes.loader} />
                        </div>
                    )}
                    
                    <Grid item className={classes.sidebarContainer} sm={3} lg={2} xs={12}>
                        <Sidebar
                            activeMenuID = {activeMenuID}
                            navigateToMenuHandler = {this.navigateToMenuHandler}
                            closeModalHandler = {closeModalHandler}
                            ratePlan={this.state.ratePlan}
                            handleDeleteRatePlan={this.handleDeleteRatePlan}
                            handleToggleDemo={() => this.setState({video: true})}
                        />
                    </Grid>

                    <Grid item sm={9} lg={10} xs={12}>
                        <ContentRenderer
                            activeMenuID = {activeMenuID}
                            contentProps = {contentProps}
                        />
                    </Grid>
                </Grid>

                {/*----------------DEMO VIDEO--------------------- */}
                <Dialog 
                    open={this.state.video} 
                    maxWidth='lg'
                    onClose={() => this.setState({video: false})}
                >   
                    <iframe 
                        width="800" 
                        height="488" 
                        src="https://www.loom.com/embed/67c3f22f1708457bba6486a4b86718a2" 
                    ></iframe>
                </Dialog>

                {/* ------------- Snackbar error messages -------------- */}
                {errors && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                        'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{errors}</span>}
                    />
                )}
            </Dialog>
        );
    }
}

const mapStateToProps = state =>{
    const { spaces, ratePlans, dashboard, loading, errors, success } = state;
    
    return{
        currentSpace: spaces[dashboard.currentSpace] || {},
        ratePlans: ratePlans,

        isLoading: loading.ADD_RATE_PLAN || 
            loading.EDIT_RATE_PLAN ||
            loading.DELETE_RATE_PLAN,

        errors: errors.ADD_RATE_PLAN || 
            errors.EDIT_RATE_PLAN ||
            errors.DELETE_RATE_PLAN,

        success: success.ADD_RATE_PLAN
    }
}

export default withStyles(styles)(connect(mapStateToProps)(RatePlanFLow));