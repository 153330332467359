import React, {useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles, Badge, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import Select, { components } from "react-select";
import clsx from 'clsx';

import { editUser, uploadUserAvatar, saveLanguageSetting } from '../../redux/actions/user/user';
import http from '../../redux/utils/http';
import { initials } from '../utils/wordUtils';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import InputBase from '@material-ui/core/InputBase';
import Avatar from '@material-ui/core/Avatar';
import Snackbar from '@material-ui/core/Snackbar';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles( theme => ({
  dialog: {
    overflowY: 'scroll'
  },
  divider: {
    marginTop: 20,
    marginBottom: 20
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 1px 20px #F2F2F2",
    display: 'flex',
    justifyContent: 'center',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  listSection: {
    margin: theme.spacing(3),
  },
  avatarContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  avatar: {    
    backgroundColor: '#bdbdbd',
    borderRadius: "50%",
    color: "#ffffff",
    fontSize: '7rem',
    height: 150,
    lineHeight: 2.3,
    marginBottom: 10,
    textAlign: 'center',
    width: 150
  },
  imageInput: {
    marginRight: 20
  },
  inputContainer: {
    marginTop: 20,
    padding: theme.spacing(4),
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(2)
  },
  submitButton: {
    background: '#000000',
    borderRadius: 25,
    boxShadow: 'none',
    fontSize: '1.4rem',
    fontWeight: 600,
    '&:hover': {
      background: '#000000',
    }
  },
  cancelButton: {
    marginRight: 10
  },
  button: {
    marginTop: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  inputField: {
    marginTop: 15
  },
  // errorText: {
  //   color: theme.palette.primary.dark
  // },
  input: {
    display: "none"
  },
  uploadButton: {
    fontSize: '1.3rem',
    marginTop: 10,
  },
  camera: {
    height: '1.6em',
    width: '1.6em',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 1.3,
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '80%',
    '& input': {
      padding: 0
    }
  },
  warning: {
    color: '#ff9800'
  },
  multiLine: {
    alignItems: 'flex-start',
    height: 100,
    overflowY: 'hidden'
  },
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 20px #F2F2F2',
    borderRadius: 25,
    display: 'flex'
  },
  select: {
    height: 35,
    paddingLeft: 20,
    width: 'calc(100% - 20px)',
  },
  staticDisplay:{
    fontWeight: 500,
    width: '100%'
  },
  errorText: {
    color: 'red',
    fontSize: '1.2rem',
    marginTop: 5,
  },
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: 'none',
    minWidth: '180px',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
}

const EditUser = (props) => {
  const classes = useStyles();
  const { user, editUserSuccess, currentSpace, t, uiSettings } = props;
  const [inputs, setInputs] = React.useState({
    edit: false,
    imageHash: '',
    oldPassword: '',
    password: '',
    password2: ''
  });

  const [file, setFile] = React.useState(null);
  const [passworMismatch, setPasswordMismatch] = React.useState(false);
  const [passwordSuccess, setPasswordSuccess] = React.useState(false);
  const [preview, setPreview] = React.useState(props.user.avatar ? props.user.avatar : null);
  const [form, setForm] = React.useState({});
  const [editSuccess, setEditSuccess] = React.useState(false);
  const [imgError, setImgError] = React.useState(false);
  const [init, setInit] = React.useState(true);
  const [error, setError] = React.useState(({ 
    passwordLength: false,
    passwordCharacter: false, 
    passwordNumber: false,
    passwordEntry: false  
  }));

  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'it', label: 'Italian' },
    { value: 'zh', label: 'Mandarin' },
    { value: 'pt', label: 'Português' },
    { value: 'de', label: 'Deutsche' },
    { value: 'ja', label: 'Japanese' }
  ]

  const [language, setLanguage] = React.useState(null)


  if((init) && (uiSettings)) {
    languageOptions.map((item) =>{
      if(uiSettings.language === item.value) 
        setLanguage({...language, value: item.value, label: item.label })
    })
    setInit(false)
  }
  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    if (!file) { return }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [file]);

  useEffect(() => {
    setEditSuccess(Boolean(editUserSuccess));
  }, [editUserSuccess])
  
  const handleInputChange = (event) => {
    setForm({...form, [event.target.name]: event.target.value});
  }

  const handlePasswordChange = (event) => {

    const value = event.target.value;
    const name = event.target.name;
    const id = event.target.id;

    setInputs({...inputs, [name]: value });

    if (id === 'password'){ 
      const { passwordErrors } = validatePassword(value);
      setError({ ...error, ...passwordErrors });
      return;
    }
  }

  const handleSaveChanges = () => {
    props.dispatch(editUser(form));
    setForm({});
  }

  const validatePassword = (password = '') =>{
    let errors = {};
    let hasError = false;

    const validations = [
      { field: 'passwordLength', status: password.length > 7 },
      { field: 'passwordCharacter', status: (/(?=.*[a-z])(?=.*[A-Z])/).test(password) },
      { field: 'passwordNumber', status: (/([0-9])/).test(password) },
      { field: 'passwordEntry', status: Boolean(password) },
    ];

    validations.forEach(data =>{ 

      const isInvalid = !data.status;

      errors[data.field] = isInvalid; 

      if(isInvalid) hasError = true;

    });

    return { passwordErrors: { ...errors }, hasError }
  }

  const handleUpdatePassword = () => {
    // const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

    setPasswordMismatch(false);

    //-------------- PASSWORD CRITERIA CHECK---------------
    const { passwordErrors, hasError } = validatePassword(inputs.password);
    if(hasError){ 
      setError({ ...error, ...passwordErrors });
      return;
    }

    // if (!inputs.password) {
      
    //   setError({ ...error, passwordFormat: false, passwordEntry: true });
    //   return;

    // } else if (!inputs.password.match(passw)) {
      
    //   setError({ ...error, passwordFormat: true, passwordEntry: false });
    //   return;
    // }

    if (inputs.password === inputs.password2) {
      http
      .patch(`/api/users/password`, {
        oldPassword: inputs.oldPassword,
        newPassword: inputs.password
      })
      .then(successResult => {
        setInputs(inputs => ({...inputs, password: '', password2: '', oldPassword: ''}));  
        setPasswordSuccess(true); 
      })
      .catch(errorResult => {
        console.log(errorResult);
      });
    } else {
      setPasswordMismatch(true)
    }
  }

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(undefined)
      return
    }
    let file = e.target.files[0];
    setFile(file)
    if ((file.size / (1024 * 1024)) < 5) {
      setImgError(false)
    } else {
      setImgError(true)
      console.log('Please upload file with size less than 5MB', (file.size / (1024 * 1024)))
    }
  }

  const handleAvatarUpload = () => {
    props.dispatch(uploadUserAvatar(props.user._id, file));
  }

  const handleLanguageChange = event => {
    props.i18n.changeLanguage(event.value);
    setLanguage({...language, label: event.label, value: event.value})

    const data = {
      spaceID: currentSpace._id,
      language: event.value
    }
    props.dispatch(saveLanguageSetting(data));
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.closeEditUser}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>{t('navbar.editProfile')}</div>
          <CloseIcon className={classes.closeIcon} onClick={props.closeEditUser} />
        </div>

        <div className={classes.listSection}>
          <div className={classes.avatarContainer}>
            <Badge
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={<div>
                <label htmlFor="outlined-button-file">
                  <CameraAltIcon className={classes.camera}/>
                </label>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="outlined-button-file"
                  type="file"
                  onChange={handleCapture}
                />
              </div>}
            >
              <Avatar
                alt={initials(props.user.name)}
                className={classes.avatar}
                src={preview} />
            </Badge>
            {imgError && <span className={classes.warning}>The selected file is too large. Please select a file less than 5MB</span>}
            {!imgError && file &&
              (<Button
                variant="outlined"
                component="span"
                className={classes.uploadButton}
                onClick={() => handleAvatarUpload()}
              >
                <PublishIcon /> Upload
              </Button>)
            }
          </div>

          <div className={classes.inputContainer}>

            <div className={classes.row}>
              <div className={classes.title}>
                {t('fields.name')}
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="name"
                  name="name"
                  defaultValue={user.name}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                {t('fields.email')}
              </div>
              <div className={classes.field}>
                <div className={classes.staticDisplay}>{user.email}</div>
                {/* <InputBase 
                  id="emai"
                  name="email"
                  defaultValue={user.email}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                /> */}
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                {t('fields.phone')}
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="phone"
                  name="phone"
                  defaultValue={user.phone}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                {t('editProfile.hostelName')}
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="hostelName"
                  name="hostelName"
                  defaultValue={user.hostelName}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                {t('editProfile.role')}
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="role"
                  name="role"
                  defaultValue={user.role}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                {t('editProfile.location')}
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="location"
                  name="location"
                  defaultValue={user.location}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                Bio
              </div>
              <div className={clsx(classes.multiLine, classes.field)}>
                <InputBase 
                  id="bio"
                  name="bio"
                  defaultValue={user.bio}
                  fullWidth
                  multiline
                  rowsMin="3"
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                Website
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="website"
                  name="website"
                  defaultValue={user.website}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                {t('editProfile.calendarLink')}
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="calendarLink"
                  name="calendarLink"
                  defaultValue={user.calendarLink}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                LinkedIn
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="linkedInProfile"
                  name="linkedInProfile"
                  defaultValue={user.linkedInProfile}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                Instagram
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="instagramProfile"
                  name="instagramProfile"
                  defaultValue={user.instagramProfile}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                Facebook
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="facebookProfile"
                  name="facebookProfile"
                  defaultValue={user.facebookProfile}
                  fullWidth
                  type="text"
                  onChange={handleInputChange} 
                />
              </div>
            </div>

            <div className={classes.submit}>
              <Button className={classes.submitButton} variant="contained" color="primary" onClick={handleSaveChanges}>
                {t('actions.save')}
              </Button>
            </div>
          </div>

          <div className={classes.inputContainer}>
            {passworMismatch && <div className={classes.errorText}>New passwords do not match!</div>}

            <div className={classes.row}>
              <div className={classes.title}>
                {t('editProfile.currentPassword')}
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="oldPassword"
                  name="oldPassword"
                  value={inputs.oldPassword}
                  fullWidth
                  type="password"
                  onChange={handlePasswordChange} 
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                {t('editProfile.newPassword')}
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="password"
                  name="password"
                  value={inputs.password}
                  fullWidth
                  type="password"
                  onChange={handlePasswordChange} 
                />
              </div>

              {error.passwordEntry && <div className={classes.errorText}>Please enter a new password</div>}

              {!error.passwordEntry && (
                <>
                  {error.passwordLength && <div className={classes.errorText}>Minimum 8 characters required</div>}
                  {error.passwordCharacter && <div className={classes.errorText}>One uppercase and one lowercase letter required</div>}
                  {error.passwordNumber && <div className={classes.errorText}>At least 1 number required</div>}
                </>
              )}
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                {t('editProfile.repeatPassword')}
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="password2"
                  name="password2"
                  value={inputs.password2}
                  fullWidth
                  type="password"
                  onChange={handlePasswordChange} 
                />
              </div>
            </div>

            <div className={classes.submit}>
              <Button className={classes.submitButton} variant="contained" color="primary" onClick={handleUpdatePassword}>
                {t('editProfile.changePassword')}
              </Button>
            </div>
          </div>
          <Divider className={classes.divider}/>
          <div  className={classes.inputContainer}>
            <Grid container alignItems="center">
              <Grid item xs={7}>
                <div className={classes.title}>
                  {t('editProfile.selectLanguage')}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={clsx(classes.toggle, classes.select)}>
                  <Select
                    value={language}
                    styles={selectStyle}
                    options={languageOptions}
                    onChange={(e) => handleLanguageChange(e)}
                    menuPlacement="top"
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      },
                    })}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        {editSuccess && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={editSuccess}
            onClose={() => setEditSuccess(false)}
            autoHideDuration={6000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{t('editProfile.profileChangeSuccess')}</span>}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setEditSuccess(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        )}

        {passwordSuccess && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={passwordSuccess}
            onClose={() => setPasswordSuccess(false)}
            autoHideDuration={6000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{t('editProfile.passwordChangeSuccess')}</span>}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setPasswordSuccess(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        )}

        {props.loadingPhotoUpload &&
          <div className={"loadingOverlay"}>
            <CircularProgress className={"loading"} />
          </div>
        }
        
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  user: state.auth.user,
  loadingPhotoUpload: state.loading.UPDATE_USER_AVATAR,
  uiSettings: state.userUIsettings[state.spaces[state.dashboard.currentSpace]._id],
  editUserSuccess: state.success.EDIT_USER
})

export default withTranslation()(connect(mapStateToProps)(EditUser));