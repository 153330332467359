import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from 'clsx';

import HWExperiencesPage from '../components/experiences/HW_Experiences';
import HWSidebar from '../components/header/HWSidebar';
import ExperiencesContainer from "./ExperiencesContainer";

import http from '../redux/utils/http';
import { logoutUser } from "../redux/actions/auth/auth";
import { setCurrentModule, setCurrentSpace, setCurrentSpaceType } from "../redux/actions/dashboard/dashboard";
import { getHWUser } from '../redux/actions/user/user';
import { resetSuccess } from "../redux/actions/reservation/editReservation";

import { MENU } from '../utils/layout';

const useStyles = makeStyles(theme => ({
  rootMain: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  root: {
    background: '#F8F9Fb',
    height: '100%',
    overflowY: 'hidden',
    marginRight: '-17px',
    minHeight: '100vh',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: 'unset',
    }
  },
  headerSpacer:{
    minHeight: '70px',
  },
  mobileSpacer: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '110px'
    }
  },
  communityHeader: {
    background: '#FFFFFF',
    boxShadow: '0 2px 20px #E0E0E0',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    top: 0,
    right: 0,
    width: 'calc(100vw - 80px)'
  },
  sidebar: {
    background: '#ffffff',
    borderRight: '0px solid #000000',
    height: '100vh',
  },
}));

const HWExperiencesContainer = (props) => {

  const classes = useStyles();
  const { auth, dispatch, currentSpace } = props;
  const [user, setUser] = useState({});
  const [userEmail, setUserEmail] = useState('')
  const [space, setSpace] = useState({});
  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const search = props.location.search;
    const apiKey = new URLSearchParams(search).get('apiKey');  
    const propertyNumber = new URLSearchParams(search).get('propertyNumber');
    const userEmail = new URLSearchParams(search).get('userEmail');
    const userRole = new URLSearchParams(search).get('userRole');
    setUserRole(userRole);
    setUserEmail(userEmail);

    if (!apiKey || !propertyNumber || !userEmail) {
      props.history.push('/');
    }

    const response = dispatch(getHWUser(userEmail, userRole, propertyNumber));

    response.then(data => {
      if (data && data.space) {

        console.log('data', data)
        setUser(data.user);
        setSpace(data.space);

        if (auth.isAuthenticated) {
          const token = localStorage.jwtToken;
          http.
            get(`/api/user/checkTokenStatus/${token}`)
            .then(successResult => {
              console.log('Successfully verified token');
              console.log('validation', auth.user._id===data.user._id);
              if (auth.user._id===data.user._id) {
                if (data.space.hwExpFlag==='live') setLoggedIn(true);
              } else {
                dispatch(logoutUser());
              }
              setLoading(false);
            })
            .catch(errorResult => {
              // console.log(errorResult)
              dispatch(logoutUser());
              setLoading(false);
            })
        } else {
          setLoading(false);
        }

      } else {
        console.log('no response from HW server');
        props.history.push('/');
      }
    })
        
    if (auth && auth.user) {
      //  GOOGLE TAG MANAGER
      // ------------------------------------------------
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'userID': auth.user._id, 
        'spaceName': currentSpace && currentSpace.name,
        'isAdmin': currentSpace && currentSpace.admins && 
                  currentSpace.admins.some(item => item === auth.user._id)
      });
    }

  }, []);

  const handleComplete = () => {
    if (auth && auth.user) {
      //  GOOGLE TAG MANAGER
      // ------------------------------------------------
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'userID': auth.user._id, 
        'spaceName': currentSpace && currentSpace.name,
        'isAdmin': currentSpace && currentSpace.admins && 
                  currentSpace.admins.some(item => item === auth.user._id)
      });
    }
    dispatch(setCurrentModule(MENU.experience.id));
    setLoggedIn(true);
  }

  return (
    <div className={classes.rootMain}>

      {/* User not logged in - No sidebar*/}
        {/* ------------------------------------ */}
      {loggedIn && !loading &&<div className={classes.sidebar} >
        <HWSidebar />
      </div>}

      <div className={clsx(classes.root)}>
        

        {/* User not logged in */}
        {/* ------------------------------------ */}
        {!loggedIn && !loading &&
          <HWExperiencesPage 
            userRole={userRole} 
            user={user}
            userEmail={userEmail}
            space={space}
            handleComplete={handleComplete}
          />
        }

        {/* Token exists - user logged in */}
        {/* ------------------------------------ */}
        {loggedIn && !loading && <ExperiencesContainer />}

      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  mobileSidebarOpen: state.dashboard.mobileSidebarOpen,
  hwUserSuccess: state.success.GET_HW_USER
});

export default connect(mapStateToProps)(withRouter(HWExperiencesContainer));