import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import { returnCurrent } from "../../redux/selectors/dashboard";
import { loadRatePlans } from "../../redux/actions/rates/ratePlans";
import {
  loadRates,
  getStopSellDatesInfo,
  resetRates,
} from "../../redux/actions/rates/rates";
import { resetSuccess } from "../../redux/actions/common/common";
import {
  fetchDateFormat,
  fetchDatePerTimezone,
  DATE_FORMATS,
  getDatesOfRange,
} from "../../utils/utility";
import {
  loadRevOccupancy,
  resetOccupancyReport,
} from "../../redux/actions/reports/occupancy";

import CalendarDates from "../beds/CalendarDates";
import RoomTypeRate from "./RoomTypeRate";
import BulkUpdate from "./bulk_update";
import RateLogs from "./RateLogs";
import RatePlans from "../ratePlans";
import StopSellByDate from "./StopSellByDate";
import OccupancyByDate from "./OccupancyByDate";

import {
  Grid,
  Dialog,
  Button,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import HistoryIcon from "@material-ui/icons/History";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import { editRestrictions } from "../../redux/actions/rates/restrictions";

import IMAGES from "../../constants/images";

const styles = (theme) => ({
  dialog: {
    background: "#F8F9FB",
    scrollY: "hidden",
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: "calc(100vh - 100px)",
    justifyContent: "center",
    width: "100%",
  },
  loadingRateState: {
    alignItems: "center",
    display: "flex",
    height: "600px",
    justifyContent: "center",
    width: "100%",
  },
  calendarHeader: {
    background: "#F8F9FB",
    //position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  headerSection: {
    marginRight: 35,
    marginLeft: 35,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  bulkRateHolder: {
    alignItems: "center",
    display: "flex",
    height: 40,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  bulkUpdate: {
    alignItems: "center",
    background: "#006699",
    borderRadius: 5,
    color: "#FFFFFF",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    justifyContent: "center",
    // lineHeight: 1.5,
    margin: "0px 0px",
    "&:hover": {
      background: "#006699",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingLeft: 5,
      // paddingRight: 5,
    },
  },
  bulkUpdateButton: {
    border: "none",
    borderRadius: "50%",
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  bulkUpdateIcon: {
    color: "#333333",
  },
  calendarDates: {
    position: "relative",
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "calc(100vh - 150px)",
    width: "100%",
  },
  buttonHolder: {
    position: "absolute",
    top: 0,
    right: 10,
    height: 42,
  },
  button: {
    border: "0px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "2.2rem",
    marginRight: 10,
    padding: 10,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  smallButton: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
    },
  },
  accessInfo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  accessInfoHeader: {
    marginTop: "unset",
    marginBottom: "10px",
  },
  ratePlanRow: {
    marginBottom: 20,
    paddingLeft: 35,
    paddingRight: 35,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  pillContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  pill: {
    alignItems: "center",
    background: "#E2E2E2",
    borderRadius: "5px",
    color: "#666666",
    display: "flex",
    fontSize: "1.2rem",
    justifyContent: "space-between",
    marginRight: 10,
    marginBottom: 5,
  },
  pillName: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    padding: "0 10px",
  },
  pillIcon: {
    borderRadius: "50%",
    cursor: "pointer",
    padding: 6,
    opacity: 0.5,
    "& img": {
      height: 15,
      width: 15,
    },
    "&:hover": {
      opacity: 1,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
  },
  selected: {
    background: theme.palette.grey[400],
    color: "#000000",
  },
  listContainer: {
    background: "#F8F9FB",
    height: "calc(100vh - 124px)",
    overflowY: "scroll",
  },
  rateSection: {
    marginRight: 35,
    marginLeft: 35,
    paddingTop: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  noPadding: {
    paddingTop: 0,
  },
});

const Rates = (props) => {
  const { t } = props;
  const {
    classes,
    open,
    handleClose,
    currentSpace,
    roomTypes,
    ratePlans,
    rates,
    loadingRates,
    loadingRatePlans,
    successRatePlans,
    successEditRatePlan,
  } = props;
  const roomsTypesKeys = currentSpace.roomTypes ? currentSpace.roomTypes : [];
  const [bulkRate, setBulkRate] = React.useState(false);
  const [openLog, setOpenLog] = React.useState(false);
  const [editRatePlan, setEditRatePlan] = React.useState("");
  const [newRatePlan, setNewRatePlan] = React.useState(false);
  const [selectedRatePlans, setSelectedRatePlans] = React.useState([]);
  const [day, setDay] = React.useState({
    startDate: fetchDatePerTimezone(null, currentSpace.timezone),
    endDate: fetchDatePerTimezone(null, currentSpace.timezone),
    days: [],
  });

  const [stopSellDateInfo, setStopSellDateInfo] = React.useState({});

  const displayDatesCount = window.innerWidth < 901 ? 5 : 15;
  const checkIn = fetchDateFormat(
    fetchDatePerTimezone(null, currentSpace.timezone),
    DATE_FORMATS.DEFAULT_DATE
  );
  const checkOut = fetchDatePerTimezone(null, currentSpace.timezone)
    .add(displayDatesCount, "days")
    .format("YYYY-MM-DD");

  // When rate plans have been loaded earlier
  useEffect(() => {
    const startDate = day.startDate.format("YYYY-MM-DD");
    const endDate = day.startDate
      .clone()
      .add(displayDatesCount, "days")
      .format("YYYY-MM-DD");

    props.dispatch(
      loadRevOccupancy(
        currentSpace.propertyID,
        startDate,
        endDate,
        "daily",
        "currentYear"
      )
    );

    if (ratePlans.default) {
      props.dispatch(loadRatePlans(currentSpace.propertyID));
    }
    if (!ratePlans.default) {
      handleRatePlans();
    }

    fetchStopSellDatesInfo(startDate, endDate);

    document.title = `${t("beds.ratesSnapshot.name")} | Counter`;
    return function cleanup() {
      props.dispatch(resetRates());
      props.dispatch(resetOccupancyReport());
      document.title = "Counter Workspace";
    };
  }, []);

  // When waiting for rate plans to load
  useEffect(() => {
    if (successRatePlans && !ratePlans.default) {
      handleRatePlans();
      props.dispatch(resetSuccess("LOAD_RATEPLANS"));
    }
  }, [successRatePlans]);

  useEffect(() => {
    if (successEditRatePlan) {
      fetchRates(
        selectedRatePlans,
        day.startDate.format("YYYY-MM-DD"),
        day.endDate.format("YYYY-MM-DD")
      );
      props.dispatch(resetSuccess("EDIT_RATE_PLAN"));
    }
  }, [successEditRatePlan]);

  // Create option list for rate plans
  //  Set default for rate plan
  //  Fetch rates once rate plans have loaded
  const handleRatePlans = () => {
    let defaultRatePlan = [];
    Object.keys(ratePlans).map((ratePlan) => {
      if (ratePlans[ratePlan].isDefault) defaultRatePlan.push(ratePlan);
    });
    setSelectedRatePlans(defaultRatePlan);
    const calcEndDate =
      window.innerWidth < 901
        ? moment().add(4, "days")
        : moment().add(14, "days");
    fetchRates(
      defaultRatePlan,
      moment().format("YYYY-MM-DD"),
      calcEndDate.format("YYYY-MM-DD")
    );
  };

  const handleCalendarDays = (newDay) => {
    if (day.days.length !== 0) {
      fetchRates(
        selectedRatePlans,
        newDay.startDate.format("YYYY-MM-DD"),
        newDay.endDate.format("YYYY-MM-DD")
      );

      props.dispatch(
        loadRevOccupancy(
          currentSpace.propertyID,
          newDay.startDate.format("YYYY-MM-DD"),
          newDay.startDate
            .clone()
            .add(displayDatesCount, "days")
            .format("YYYY-MM-DD"),
          "daily",
          "currentYear"
        )
      );

      fetchStopSellDatesInfo(
        newDay.startDate.format("YYYY-MM-DD"),
        newDay.startDate
          .clone()
          .add(displayDatesCount, "days")
          .format("YYYY-MM-DD")
      );
    }
    setDay({ ...newDay });
  };

  const handleClick = (ratePlanID) => {
    const index = selectedRatePlans.indexOf(ratePlanID);
    if (index > -1) {
      let array = selectedRatePlans;
      array.splice(index, 1);
      setSelectedRatePlans([...array]);
    } else {
      setSelectedRatePlans([...selectedRatePlans, ratePlanID]);
      fetchRates(
        [...selectedRatePlans, ratePlanID],
        day.startDate.format("YYYY-MM-DD"),
        day.endDate.format("YYYY-MM-DD")
      );
    }
  };

  const fetchRates = (ratePlanArray, startDate, endDate) => {
    if (ratePlanArray.length !== 0) {
      props.dispatch(
        loadRates({
          propertyID: currentSpace.propertyID,
          startDate: startDate,
          endDate: endDate,
          ratePlanIDs: ratePlanArray,
        })
      );
    }
  };

  const fetchStopSellDatesInfo = (startDate, endDate) => {
    props
      .dispatch(
        getStopSellDatesInfo(currentSpace.propertyID, { startDate, endDate })
      )
      .then((data) => {
        if (data) setStopSellDateInfo({ ...data });
      });
  };

  const reloadRatesViewHandler = () => {
    const ratePlanIDs = [...selectedRatePlans];

    if (ratePlanIDs.length !== 0) {
      props.dispatch(
        loadRates({
          propertyID: currentSpace.propertyID,
          startDate: day.startDate.format("YYYY-MM-DD"),
          endDate: day.endDate.format("YYYY-MM-DD"),
          ratePlanIDs,
        })
      );
    }
  };

  const stopSellByDateHandler = (index, isStopSellApplied) => {
    const dateRange = getDatesOfRange(
      day.startDate.format("YYYY-MM-DD"),
      day.endDate.format("YYYY-MM-DD"),
      null,
      "stringArray"
    );
    const selectedDate = dateRange?.[index];

    if (selectedDate) {
      let payload = [];
      let standardPayload = {
        propertyID: currentSpace.propertyID,
        startDate: selectedDate,
        endDate: selectedDate,
        stopSell: isStopSellApplied,
      };

      let ratePlanIDs = Object.keys(ratePlans);
      let roomTypeIDs = currentSpace.roomTypes;

      for (
        let ratePlanIndex = 0;
        ratePlanIndex < ratePlanIDs.length;
        ratePlanIndex++
      ) {
        const ratePlanID = ratePlanIDs[ratePlanIndex];
        const ratePlanRoomTypeIDs = ratePlans[ratePlanID].roomTypeIDs;

        for (
          let roomTypeIndex = 0;
          roomTypeIndex < ratePlanRoomTypeIDs.length;
          roomTypeIndex++
        ) {
          const roomTypeID = ratePlanRoomTypeIDs[roomTypeIndex];
          if (
            roomTypes?.[roomTypeID]?.isActive &&
            roomTypeIDs.includes(roomTypeID)
          ) {
            payload.push({ ratePlanID, roomTypeID, ...standardPayload });
          }
        }
      }

      if (payload.length) {
        const response = props.dispatch(
          editRestrictions(currentSpace.propertyID, payload, {
            stopSellAllRooms: selectedDate,
          })
        );
        response.then((success) => {
          if (success) {
            reloadRatesViewHandler();
            setStopSellDateInfo({
              ...stopSellDateInfo,
              [selectedDate]: {
                data: selectedDate,
                stopSellAllRooms: isStopSellApplied,
              },
            });
          }
        });
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        fullScreen={true}
      >
        {loadingRatePlans && (
          <div className={classes.loadingState}>
            <CircularProgress />
          </div>
        )}

        {/* -------------------------------------------------------- */}
        {/* HEADER */}
        {/* -------------------------------------------------------- */}
        {!loadingRatePlans && (
          <div className={classes.calendarHeader}>
            <div className={classes.headerSection}>
              <Grid container>
                <Hidden smUp>
                  <Grid item xs={12} className={classes.accessInfo}>
                    <AccessHeaderAction
                      moduleID={MODULE.RATES_AND_AVAILABILITY.ID}
                      className={classes.accessInfoHeader}
                    />
                  </Grid>
                </Hidden>
                <Grid item xs={4} sm={2}>
                  <div className={classes.bulkRateHolder}>
                    <Button
                      className={classes.bulkUpdate}
                      onClick={() => setBulkRate(true)}
                    >
                      {t("beds.ratesSnapshot.bulkUpdate")}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={8} sm={10} className={classes.calendarDates}>
                  <CalendarDates
                    dateSelectEnabled={false}
                    calendarDaysEnabled={true}
                    calendarChangeEnabled={true}
                    numberOfDays={displayDatesCount}
                    datePicker={true}
                    checkIn={checkIn}
                    checkOut={checkOut}
                    timezone={currentSpace.timezone}
                    dateFormat={currentSpace.dateFormat}
                    handleCalendarDays={handleCalendarDays}
                    showAccessHeader={true}
                    moduleID={MODULE.RATES_AND_AVAILABILITY.ID}
                  />
                  <div className={classes.buttonHolder}>
                    <HistoryIcon
                      className={clsx(classes.button, classes.smallButton)}
                      onClick={() => setOpenLog(true)}
                    />
                    <CloseIcon
                      className={classes.button}
                      onClick={handleClose}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        )}

        {!loadingRatePlans && (
          <div className={classes.listContainer}>
            {/* -------------------------------------------------------- */}
            {/* PILLS AND NEW RATE PLAN BUTTON */}
            {/* -------------------------------------------------------- */}
            <div className={classes.ratePlanRow}>
              <Grid container>
                <Grid item xs={9} sm={10}>
                  <div className={classes.pillContainer}>
                    {Object.keys(ratePlans).length !== 0 &&
                      Object.keys(ratePlans).map(
                        (ratePlanItem) =>
                          ratePlans[ratePlanItem].isDeleted !== true && (
                            <div
                              key={ratePlanItem}
                              className={clsx({
                                [classes.pill]: true,
                                [classes.selected]:
                                  selectedRatePlans.indexOf(ratePlanItem) > -1,
                              })}
                            >
                              <div
                                className={classes.pillName}
                                onClick={() => handleClick(ratePlanItem)}
                              >
                                {ratePlans[ratePlanItem].privateName}
                              </div>
                              <div
                                className={classes.pillIcon}
                                onClick={() => setEditRatePlan(ratePlanItem)}
                              >
                                <img src={IMAGES.ICONS.settings} />
                              </div>
                            </div>
                          )
                      )}
                  </div>
                </Grid>
                <Grid item xs={3} sm={2}>
                  <div className={classes.buttonContainer}>
                    <Button
                      className={classes.bulkUpdate}
                      onClick={() => setNewRatePlan(true)}
                    >
                      New Rate Plan
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>

            {/* -------------------------------------------------------- */}
            {/* STOPSELL BY DATE */}
            {/* -------------------------------------------------------- */}
            {!loadingRates && (
              <div className={classes.rateSection}>
                <StopSellByDate
                  numberOfDays={displayDatesCount}
                  stopSellByDateHandler={stopSellByDateHandler}
                  startDate={day.startDate}
                  endDate={day.endDate}
                  stopSellDateInfo={stopSellDateInfo}
                />
              </div>
            )}

            {/* -------------------------------------------------------- */}
            {/* STOPSELL BY DATE */}
            {/* -------------------------------------------------------- */}
            {!loadingRates && (
              <div className={clsx(classes.rateSection, classes.noPadding)}>
                <OccupancyByDate
                  numberOfDays={displayDatesCount}
                  startDate={day.startDate}
                />
              </div>
            )}

            {/* -------------------------------------------------------- */}
            {/* RATE PLAN DISPLAY */}
            {/* -------------------------------------------------------- */}
            {!loadingRates && (
              <div className={classes.rateSection}>
                {Object.keys(rates).length !== 0 &&
                  roomsTypesKeys.length !== 0 &&
                  roomsTypesKeys.map((value) => {
                    if (!roomTypes?.[value]?.isActive) return null;

                    return (
                      <RoomTypeRate
                        numberOfDays={displayDatesCount}
                        roomTypeID={value}
                        rate={rates[value]}
                        key={value}
                        selectedRatePlans={selectedRatePlans}
                        reloadRatesViewHandler={reloadRatesViewHandler}
                      />
                    );
                  })}
              </div>
            )}

            {loadingRates && (
              <div className={classes.loadingRateState}>
                <CircularProgress />
              </div>
            )}
          </div>
        )}
      </Dialog>

      {bulkRate && (
        <BulkUpdate
          open={bulkRate}
          handleClose={() => setBulkRate(false)}
          reloadRatesViewHandler={reloadRatesViewHandler}
          calendarData={{
            startDate: day.startDate.format("YYYY-MM-DD"),
            endDate: day.endDate.format("YYYY-MM-DD"),
          }}
        />
      )}

      <RateLogs open={openLog} closeLog={() => setOpenLog(false)} />

      {(newRatePlan || editRatePlan !== "") && (
        <RatePlans
          open={newRatePlan || editRatePlan !== ""}
          handleClose={() => {
            setNewRatePlan(false);
            setEditRatePlan("");
          }}
          ratePlanID={editRatePlan !== "" ? editRatePlan : null}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  roomTypes: state.roomTypes,
  rates: state.rates,
  ratePlans: state.ratePlans,
  loadingRates:
    state.loading.LOAD_RATES ||
    state.loading.EDIT_RESTRICTION ||
    state.loading.FETCH_STOP_SELL_DATES_INFO,
  loadingRatePlans: state.loading.LOAD_RATEPLANS,
  successRatePlans: state.success.LOAD_RATEPLANS,
  successEditRatePlan: state.success.EDIT_RATE_PLAN,
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps)(Rates))
);
