import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { InputBase } from '@material-ui/core';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ConfirmForm from '../../common/ConfirmForm';
import NumberInput from '../../common/NumberInput';
import { uploadRoomTypePhoto } from '../../../redux/actions/rooms/roomTypes';
import DeleteConfirm from '../DeleteConfirm';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
	submit: {
		display: 'flex',
		justifyContent: "flex-end",
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	picContainer: {
		alignItems: 'center',
		// cursor: 'pointer',
		display: 'flex',
	},
	placeholder: {
		background: '#F2F2F2',
		borderRadius: '15px',
		height: 80,
		marginRight: 15,
		overflow: 'hidden',
		width: 80,
	},
	image: {
		borderRadius: 15,
		objectFit: 'cover',
		height: 80,
		width: 80,
	},
	label: {
		fontWeight: 600,
		fontSize: '1.1rem',
		marginBottom: 10,
	},
	link: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		marginBottom: 0,
	},
	warning: {
		color: '#ff9800',
		marginTop: 10
	},
	input: {
		display: 'none'
	},
	deleteButton: {
		borderRadius: '50%',
		cursor: 'pointer',
		height: 20,
		padding: 8,
		width: 20,
		'&:hover': {
			background: '#E0E0E0',
		}
	},
	leftAlignSubmit: {
		width: '60%'
	},
	righttAlignSubmit: {
		width: '40%',
		display: 'flex',
		justifyContent: "flex-end",
	},
	cancelButton: {
		margin: '0 10px'
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		margin: 20,
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
	},
	row: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginBottom: 30,
	},
	field: {
		alignItems: 'center',
		boxShadow: "0 1px 10px #E0E0E0",
		borderRadius: '10px',
		display: 'flex',
		height: 35,
		marginTop: 10,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		width: 'calc(80% - 32px)',
		'& > div': {
			width: '100%'
		},
		'&.disabled': {
			backgroundColor: '#E0E0E0'
		}
	},
	fieldError: {
		border: '1px solid red',
	},
	select: {
		boxShadow: '0 1px 10px #E0E0E0',
		borderRadius: 10,
		marginTop: 10,
		width: '80%',
	},
	multiLine: {
		alignItems: 'flex-start',
		height: 100,
		overflowY: 'hidden'
	},
	cancel: {
		color: '#999999',
		fontSize: '1.2rem',
		marginRight: 15,
		'&:hover': {
			background: '#ffffff'
		}
	},
	imageContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginBottom: 40,
	},
	uploadButton: {
		color: '#999999',
		fontSize: '1.2rem',
		marginTop: 10,
	},
	camera: {
		display: 'inline-block',
		height: 15,
		opacity: 0.5,
		width: 15,
		marginRight: 10
	},
	input: {
		display: "none"
	},
	back: {
		'&:hover': {
			background: '#ffffff'
		}
	}
}))

const selectStyle = {
	control: (base, state) => ({
		...base,
		border: 0,
		borderRadius: 10,
		paddingLeft: 8,
		'&:hover': {
			border: state.isFocused ? 0 : 0
		},
		paddingLeft: 0,
		'[type="text"]': {
			fontFamily: 'Roboto, sans-serif !important',
			fontSize: '1.4rem',
			color: 'rgba(0, 0, 0, 0.87)'
		}
	})
};

const RoomTypeForm = (props) => {
	const classes = useStyles();
	const { data, taxes, currentSpace, currentUser, t } = props;
	const isSystemAdmin = currentUser?.isSystemAdmin;
	const [error, setError] = React.useState({ name: false });
	const [preview, setPreview] = React.useState(data.images && data.images.length ? data.images[data.images.length - 1].imagelink : null);
	const [selectedFile, setSelectedFile] = React.useState();
	const [imgError, setImgError] = React.useState(false);
	const [confirmOpen, setConfirmOpen] = React.useState(false)
	const taxArray = currentSpace.taxes && currentSpace.taxes.map(tax => { return { value: tax, label: taxes[tax] && taxes[tax].name } });
	const [form, setForm] = React.useState({ 
		name: data.name || '',
		code: data.code || '',
		dormOrPrivate: data.dormOrPrivate || 'private' 
	});

	useEffect(() => {
		if (!selectedFile) {
			// setPreview(undefined)
			return
		}
		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl)

		// free memory when ever this component is unmounted
		return () => URL.revokeObjectURL(objectUrl)
	}, [selectedFile]);

	const handleCapture = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined)
			return
		}
		if ((e.target.files[0].size / (1024 * 1024)) < 5) {
			setSelectedFile(e.target.files[0])
			setForm({ ...form, image: e.target.files[0] })
			setImgError(false)
		} else {
			setImgError(true)
			// console.log('Please upload file with size less than 5MB', (e.target.files[0].size / (1024 * 1024)))
		}
	}

	const handleChange = (e, name) => {
		setForm({ ...form, [name]: e.target.value })
		if (e.target.value !== '') {
			setError({ ...error, [name]: false })
		}
	}

	const handleSelect = selected => {
		if (selected !== null) {
			const taxIDArray = selected.map(item => item.value)
			setForm({ ...form, taxesApplicable: taxIDArray })
		} else {
			setForm({ ...form, taxesApplicable: [] })
		}
	}

	const handleToggle = event => setForm({ ...form, dormOrPrivate: event.target.value });

	const handleValidate = () => {
		let foundError = false;
		let errorStep = {};
		let mandatoryFields = props.roomTypeID ? 
			['name', 'dormOrPrivate'] :
			['name', 'dormOrPrivate', 'rackRate', 'bedsPerRoom'];
		mandatoryFields.map(item => {
			if (!form[item]) {
				errorStep[item] = true;
				foundError = true;
			}
		})
		if (foundError) {
			setError({ ...error, ...errorStep })
			return false
		}
		else return true
	}

	const handleSubmit = () => {
		console.log("form", form)
		const checkInput = handleValidate();
		if (checkInput) {
			if(selectedFile && props.roomTypeID){
				handleUpload()
			}
			props.handleSubmit({
				...form,
				totalAccommodation: "0",
				numberOfUnits: 0
			});
		}
	}

	const handleUpload = () => {
		// console.log("selectedFile", selectedFile)
		const fileParts = selectedFile.name.split(".");
		const fileName = fileParts[0];
		const fileType = fileParts[1];
		const payload = {
		  propertyID: currentSpace.propertyID,
		  roomTypeID: props.roomTypeID,
		  file: selectedFile,
		  name: fileName,
		  type: fileType
		};
	
		// console.log("payload", payload)
		props.dispatch(uploadRoomTypePhoto(payload))
		// setSelectedFile(null)
	  }

	const handleConfirmFormClose = () => {
		setConfirmOpen(false);
	}

	const handleConfirmDelete = (value) => {
		props.handleConfirmDelete(value)
		setConfirmOpen(false);
	}


	return (
		<div className={classes.root}>
			<div className={classes.row}>
				<div className={classes.picContainer}>
					<div className={classes.placeholder}>
						{preview ?
							<img className={classes.image} src={preview} /> :
							<img className={classes.image} src={IMAGES.ICONS.posPlaceholder} />
						}
					</div>
					<label htmlFor="outlined-button-file"><div className={clsx(classes.link, classes.label)}>{t('beds.roomTypesAndRooms.uploadPic')}</div></label>
					<input
						accept="image/*"
						className={classes.input}
						id="outlined-button-file"
						type="file"
						onChange={handleCapture}
					/>
					{imgError && <span className={classes.warning}>{t('onboarding.roomType.picError')}</span>}
				</div>
			</div>

			<div className={classes.row}>
				<div className={classes.label}>{t('fields.name')}</div>
				<div className={clsx(classes.field, { [classes.fieldError]: error.name })}>
					<InputBase
						id="name"
						name="Name"
						type="text"
						defaultValue={data.name}
						onChange={(e) => handleChange(e, "name")}
					/>
				</div>
			</div>

			<div className={classes.row}>
				<div className={classes.label}>{t('beds.roomTypesAndRooms.code')}</div>
				<div className={clsx(classes.field, { [classes.fieldError]: error.code })}>
					<InputBase
						id="code"
						name="Code"
						type="text"
						defaultValue={data.code}
						onChange={(e) => handleChange(e, "code")}
					/>
				</div>
			</div>

			{props.roomTypeID && <div className={classes.row}>
				<div className={classes.label}>{t('beds.roomTypesAndRooms.internalCode')}</div>
				<div className={clsx(classes.field, { 'disabled': true })}>
					<InputBase
						id="internalCode"
						name="Code"
						type="text"
						disabled
						defaultValue={data.internalCode}
					/>
				</div>
			</div>}

			<div className={classes.row}>
				<FormControl component="fieldset">
					<RadioGroup aria-label="position" name="position" value={form.dormOrPrivate} onChange={handleToggle} row>
						<FormControlLabel
							value="private"
							control={<Radio color="primary" />}
							label={t('beds.roomTypesAndRooms.private')}
							labelPlacement="end"
							disabled={props.roomTypeID ? true : false}
						/>
						<FormControlLabel
							value="dorm"
							control={<Radio color="primary" />}
							label={t('beds.roomTypesAndRooms.shared')}
							labelPlacement="end"
							disabled={props.roomTypeID ? true : false}
						/>
					</RadioGroup>
				</FormControl>
			</div>

			<div className={classes.row}>
				<div className={classes.label}>{t('beds.roomTypesAndRooms.description')}</div>
				<div className={clsx(classes.multiLine, classes.field)}>
					<InputBase
						id="description"
						name="description"
						fullWidth
						multiline
						rows="5"
						type="text"
						defaultValue={data.description}
						onChange={(e) => handleChange(e, "description")}
					/>
				</div>
			</div>

			{!props.roomTypeID &&
				<div className={classes.row}>
					<div className={classes.label}>{t('beds.ratesSnapshot.baseRate')}</div>
					<div className={clsx(classes.field, { [classes.fieldError]: error.rackRate })}>

						<NumberInput
							id="rackRate"
							name="rackRate"
							min={0}
							value={(form.rackRate && form.rackRate !== '') ? form.rackRate : ''}
							onChange={(e) => handleChange(e, 'rackRate')} />
					</div>
				</div>
			}

			<div className={classes.row}>
				<div className={classes.label}>{t('beds.roomTypesAndRooms.guestsPerRoom')}</div>
				<div className={clsx(classes.field, { [classes.fieldError]: error.bedsPerRoom, 'disabled': props.roomTypeID ? true : false })}>
					<NumberInput
						id="numberOfUnits"
						name="bedsPerRoom"
						disabled={props.roomTypeID ? true : false}
						min={0}
						value={props.roomTypeID ? data.bedsPerRoom : (form.bedsPerRoom && form.bedsPerRoom !== '') ? form.bedsPerRoom : ''}
						onChange={(e) => handleChange(e, 'bedsPerRoom')}
					/>
				</div>
			</div>

			<div className={classes.row}>
				<div className={classes.label}>{t('existingReservation.summaryTab.tax')}</div>
				<div className={clsx(classes.select, { [classes.fieldError]: error.taxesApplicable })}>
					{/* {defaultTaxes && */}
					<Select
						defaultValue={data?.taxesApplicable?.map(taxID => taxArray?.find(e => e.value === taxID))}
						isMulti
						onChange={handleSelect}
						options={taxArray}
						placeholder={t('beds.roomTypesAndRooms.applyTaxes')}
						styles={selectStyle}
						className={classes.dropdownInput}
						theme={theme => ({
							...theme,
							border: 0,
							colors: {
								...theme.colors,
								primary: '#666666',
								primary25: '#dddddd'
							},
						})}
					/>
					{/* } */}
				</div>
			</div>
			{/* --------------------------------------------------------------------- */}
			{/* SUBMIT / CANCEL / DELETE UPDATES */}
			{/* --------------------------------------------------------------------- */}

			<div className={classes.submit}>
				<div className={classes.leftAlignSubmit}>
					{confirmOpen &&
						<DeleteConfirm
							type={'Room type'}
							title={t('beds.roomTypesAndRooms.deleteRoomType')}
							submitHandler={handleConfirmDelete}
							closeModalHandler={handleConfirmFormClose}
							isSystemAdmin={isSystemAdmin}
						/>
					}

					{!confirmOpen && props.roomTypeID &&
						<img src={IMAGES.ICONS.bin} className={classes.deleteButton} onClick={() => setConfirmOpen(true)} />
					}

				</div>
				<div className={classes.righttAlignSubmit}>
					<Button className={classes.cancel} onClick={props.handleCancel}>{t('actions.cancel')}</Button>
					<Button variant="contained" color="primary" onClick={() => handleSubmit()}>{t('actions.save')}</Button>
				</div>
			</div>
		</div >
	)
}

const mapStateToProps = (state) => ({
	currentSpace: state.spaces[state.dashboard.currentSpace],
	currentUser: state.auth.user,
	taxes: state.taxes
})

export default withTranslation()(connect(mapStateToProps)(RoomTypeForm));