import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { returnCurrent } from '../../redux/selectors/dashboard';
import { editRates } from '../../redux/actions/rates/rates';

import { Grid, Checkbox, TextField, Tooltip, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { editRestrictions } from '../../redux/actions/rates/restrictions';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 40,
    '&:hover': {
      backgroundColor: '#F8F9FB',
      cursor: 'pointer',
    }
  },
  row: {
    display: 'flex'
  },
  selected: {
    backgroundColor: '#F8F9FB',
    paddingTop: 15
  },
  pointer: {
    cursor: 'pointer'
  },
  editIcon: {
    color: '#bdbdbd',
    cursor: 'pointer',
    fontSize: '1.6rem',
    marginLeft: 10,
  },
  rateName: {
    fontSize: '1.2rem',
    marginLeft: 10,
  },
  rateItem: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.2rem',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  lineName: {
    marginLeft: 10,
    paddingLeft: 10,
    alignItems: 'flex-start'
  },
  advanced: {
    color: '#999999',
    cursor: 'pointer',
    fontSize: '1.2rem',
    marginTop: 5
  },
  lineItem: {
    alignItems: 'center',
    color: '#999999',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    height: 30,
  },
  spacing: {
    marginTop: 5,
    marginBottom: 5
  },
  darkText: {
    color: '#333333'
  },
  noClick: {
    cursor: 'default'
  },
  textField: {
    marginRight: 10,
    marginLeft: 10,
  },
  checkbox: {
    color: '#999999',
    cursor: 'pointer',
    height: 30,
    padding: 0,
    '& input': {
      color: '#999999'
    },
  },
  cancelButton: {
    marginRight: 10
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}))

const GreyTextField = withStyles({
  root: {
    // '& .MuiInputBase-input': {
    //   color: '#fff', // Text color
    // },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#BDBDBD', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#999999', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#999999', // Solid underline on focus
    },
  },
})(TextField);

const checkBoxStyles = theme => ({
  root: {
    '&$checked': {
      color: '#666666',
    },
  },
  checked: {},
 })

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const RatePlanRow = (props) => {
  const { t } = props
  const classes=useStyles();
  const { currentSpace, ratePlan, roomTypeID, ratePlans, numberOfDays } = props;
  const [advancedFlag, setAdvancedFlag] = React.useState(false);
  const [editFlag, setEditFlag] = React.useState(false);
  const [form, setForm] = React.useState([]);
  const [restriction, setRestriction] = React.useState([]);
  const width = (100/numberOfDays);

  const handleChangeRate = (e, date) => {
    const match = form.findIndex(e => e.startDate===date && e.endDate===date);
    if (match !== -1) {
      const newArr = form
      newArr[match] = {...form[match], [e.target.name]: parseFloat(e.target.value)};
    } else {
      setForm(
        [
          ...form, 
          {
            propertyID: currentSpace.propertyID, 
            roomTypeID: roomTypeID, 
            startDate: date, 
            endDate: date, 
            ratePlanID: ratePlan.ratePlanID,
            amount: parseFloat(e.target.value)
          }
        ]
      )
    }
  }

  const handleChangeRestriction = (e, date) => {
    const match = restriction.findIndex(e => e.startDate===date && e.endDate===date);
    if (match !== -1) {
      const newArr = restriction
      newArr[match] = {...restriction[match], [e.target.name]: parseInt(e.target.value)};
    } else if (ratePlan.ratePlanID==='default') {
      setRestriction(
        [
          ...restriction, 
          {
            propertyID: currentSpace.propertyID, 
            roomTypeID, 
            startDate: date, 
            endDate: date, 
            [e.target.name]: parseInt(e.target.value)
          }
        ]
      )
    } else {
      setRestriction(
        [
          ...restriction, 
          {
            propertyID: currentSpace.propertyID, 
            roomTypeID, 
            ratePlanID: ratePlan.ratePlanID,
            startDate: date, 
            endDate: date, 
            [e.target.name]: parseInt(e.target.value)
          }
        ]
      )
    }
  }

  const handleToggle = (e, date) => {
    const match2 = restriction.findIndex(e => e.startDate===date && e.endDate===date);
    if (match2 !== -1) {
      restriction[match2] = {...restriction[match2], [e.target.value]: e.target.checked};
    } else if (ratePlan.ratePlanID==='default') {
      setRestriction(
        [
          ...restriction, 
          {
            propertyID: currentSpace.propertyID, 
            roomTypeID,
            startDate: date, 
            endDate: date, 
            [e.target.value]: e.target.checked
          }
        ]
      )
    } else {
      setRestriction(
        [
          ...restriction, 
          {
            propertyID: currentSpace.propertyID, 
            roomTypeID, 
            ratePlanID: ratePlan.ratePlanID,
            startDate: date, 
            endDate: date, 
            [e.target.value]: e.target.checked
          }
        ]
      )
    }
  }

  const handleEdit = () => {
    if (!editFlag) {
      setEditFlag(true);
      setAdvancedFlag(true);
    }
  }

  const handleCancel = () => {
    setEditFlag(false);
    setAdvancedFlag(false);
    setForm([]);
  }

  const submitUpdates = () => {
    console.log('Edit rates & restrictions - Submit button', form, restriction )
    if (restriction.length) {
      const response = props.dispatch(editRestrictions(currentSpace.propertyID, restriction));
      response.then(success =>{
        if(success) props.reloadRatesViewHandler();
      });
    }
    if (form.length) {
      const response = props.dispatch(editRates(currentSpace.propertyID, form));
      response.then(success =>{
        if(success) props.reloadRatesViewHandler();
      });
    }
    setForm([]);
    setRestriction([]);
    setEditFlag(false);
  }

  const multiCurrency = ratePlans[ratePlan.ratePlanID].multiCurrency

  return(
    <Grid 
      container 
      className={clsx({[classes.container]: true, [classes.selected]: editFlag || advancedFlag, [classes.pointer]: editFlag && !advancedFlag})} 
      alignItems="center"
      onClick={!advancedFlag ? handleEdit : null}
    >

      {/* ROW NAME */}
      {/* ---------------------------------------------------------------- */}
      <Grid item xs={4} sm={2}>
        <div className={clsx(classes.rateItem, classes.lineName)}>
          <div 
            className={clsx({[classes.lineItem]: true, [classes.spacing]: editFlag, [classes.darkText]: editFlag})} 
            onClick={handleEdit}
          >
            {ratePlans[ratePlan.ratePlanID].privateName}
            {ratePlans[ratePlan.ratePlanID].isDerived && ' (derived)'}
            {multiCurrency && multiCurrency.enabled ? ` (${multiCurrency.currency})` : ''}
          </div>
        </div>

        {advancedFlag &&
          <div className={clsx(classes.rateItem, classes.lineName)}>
            <div className={clsx({[classes.lineItem]: true, [classes.noClick]: true, [classes.spacing]: editFlag, [classes.darkText]: editFlag})}>
              minLOS
            </div>
            <div className={clsx({[classes.lineItem]: true, [classes.noClick]: true, [classes.spacing]: editFlag, [classes.darkText]: editFlag})}>
              maxLOS
            </div>
            <div className={clsx({[classes.lineItem]: true, [classes.noClick]: true, [classes.spacing]: editFlag, [classes.darkText]: editFlag})}>
              {t('beds.ratesSnapshot.cta')}
            </div>
            <div className={clsx({[classes.lineItem]: true, [classes.noClick]: true, [classes.spacing]: editFlag, [classes.darkText]: editFlag})}>
              {t('beds.ratesSnapshot.ctd')}
            </div>
            <div className={clsx({[classes.lineItem]: true, [classes.noClick]: true, [classes.spacing]: editFlag, [classes.darkText]: editFlag})}>
              {t('beds.ratesSnapshot.stopSell')}
            </div>
          </div>
        }
      </Grid>

      <Grid item xs={8} sm={10} className={classes.row}>
        {ratePlan && ratePlan.data.map(rateItem => 
          <div className={classes.column} key={rateItem.date} style={{width: `${width}%`}}>
            <Tooltip title={rateItem.stopSell ? "Stop Sell" : ""}>
              <div 
                className={classes.rateItem} 
                style={{
                  background: rateItem.stopSell ? '#E4ACA9' : 'transparent',
                }}
              >
                
                {/* ----------------------------------------------------------------------------- */}
                {/* Rate */}
                {/* ----------------------------------------------------------------------------- */}
                <div 
                  className={clsx({[classes.lineItem]: true,[classes.spacing]: editFlag})} 
                  style={{
                    color: rateItem.stopSell ? '#CC6666' : '#999999',
                    fontWeight: rateItem.stopSell ? 600 : 500,
                  }}
                  onClick={handleEdit}
                >
                  {(ratePlans[ratePlan.ratePlanID].isDerived || !editFlag) && ((rateItem.amount || rateItem.amount === 0) ? (rateItem.amount !== -1 ? Math.round(rateItem.amount * 100) / 100 : 'NA') : '-')}
                  {!ratePlans[ratePlan.ratePlanID].isDerived && editFlag && 
                    <GreyTextField
                      name="amount"
                      type="tel"
                      className={classes.textField}
                      inputProps={{min: 0, style: { textAlign: 'center' }}}
                      defaultValue={(rateItem.amount || rateItem.amount === 0) ? rateItem.amount : null}
                      onChange={(e) => handleChangeRate(e, rateItem.date)}
                      margin="none"
                    />
                  }
                </div>
              </div>
            </Tooltip>

            {advancedFlag && <div className={classes.rateItem}>
              {/* ----------------------------------------------------------------------------- */}
              {/* minLOS */}
              {/* ----------------------------------------------------------------------------- */}
              <div 
                className={clsx({
                  [classes.lineItem]: true,
                  [classes.spacing]: editFlag
                })} 
                onClick={handleEdit}
              >
                {!editFlag && (rateItem.minLOS ? rateItem.minLOS : 1)}
                {editFlag && 
                  <GreyTextField
                    name="minLOS"
                    type="tel"
                    className={clsx({[classes.textField]: true,[classes.spacing]: editFlag})}
                    inputProps={{min: 0, style: { textAlign: 'center' }}}
                    defaultValue={rateItem.minLOS ? rateItem.minLOS : 1}
                    onChange={(e) => handleChangeRestriction(e, rateItem.date)}
                    margin="none"
                  />
                }
              </div>

              {/* ----------------------------------------------------------------------------- */}
              {/* maxLOS */}
              {/* ----------------------------------------------------------------------------- */}
              <div 
                className={clsx({
                  [classes.lineItem]: true,
                  [classes.spacing]: editFlag
                })} 
                onClick={handleEdit}
              >
                {!editFlag && (rateItem.maxLOS ? rateItem.maxLOS : 365)}
                {editFlag && 
                  <GreyTextField
                    name="maxLOS"
                    type="tel"
                    className={clsx({[classes.textField]: true,[classes.spacing]: editFlag})}
                    inputProps={{min: 0, style: { textAlign: 'center' }}}
                    defaultValue={rateItem.maxLOS ? rateItem.maxLOS : 365}
                    onChange={(e) => handleChangeRestriction(e, rateItem.date)}
                    margin="none"
                  />
                }
              </div>

              {/* ----------------------------------------------------------------------------- */}
              {/* CTA */}
              {/* ----------------------------------------------------------------------------- */}
              {!editFlag && <CustomCheckbox
                checked={rateItem.CTA || false}
                className={clsx({[classes.checkbox]: true,[classes.spacing]: editFlag})}
                disabled={!editFlag}
                value="CTA"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />}
              {editFlag && <CustomCheckbox
                defaultChecked={rateItem.CTA || false}
                onChange={(e) => handleToggle(e, rateItem.date)}
                color="primary"
                className={clsx({[classes.checkbox]: true,[classes.spacing]: editFlag})}
                disabled={!editFlag}
                value="CTA"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />}

              {/* ----------------------------------------------------------------------------- */}
              {/* CTD */}
              {/* ----------------------------------------------------------------------------- */}
              {!editFlag && <CustomCheckbox
                checked={rateItem.CTD || false}
                className={clsx({[classes.checkbox]: true,[classes.spacing]: editFlag})}
                disabled={!editFlag}
                value="CTD"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />}
              {editFlag && <CustomCheckbox
                defaultChecked={rateItem.CTD || false}
                onChange={(e) => handleToggle(e, rateItem.date)}
                color="primary"
                className={clsx({[classes.checkbox]: true,[classes.spacing]: editFlag})}
                disabled={!editFlag}
                value="CTD"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />}

              {/* ----------------------------------------------------------------------------- */}
              {/* StopSell */}
              {/* ----------------------------------------------------------------------------- */}
              {!editFlag && <CustomCheckbox
                checked={rateItem.stopSell || false}
                className={clsx({[classes.checkbox]: true,[classes.spacing]: editFlag})}
                disabled={!editFlag}
                value="stopSell"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />}
              {editFlag && <CustomCheckbox
                defaultChecked={rateItem.stopSell || false}
                color="primary"
                onChange={(e) => handleToggle(e, rateItem.date)}
                className={clsx({[classes.checkbox]: true,[classes.spacing]: editFlag})}
                disabled={!editFlag}
                value="stopSell"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />}

            </div>}
          </div>
        )}
      </Grid>      

      {editFlag && <Grid item xs={12}>
        <div className={classes.submit}>
          <Button size="small" variant="outlined" className={classes.cancelButton} onClick={handleCancel}>Cancel</Button>
          <Button 
            size="small" 
            variant="outlined" 
            className={classes.button} 
            disabled={(form.length===0 && restriction.length===0)}
            onClick={() => submitUpdates()}
          >
            {t('beds.ratesSnapshot.publishUpdates')}
          </Button>
        </div>
      </Grid>}

      {!editFlag && advancedFlag && <Grid item xs={12}>
        <div className={classes.submit}>
          <Button 
            size="small" 
            variant="outlined" 
            className={classes.button}
            onClick={() => {
              setAdvancedFlag(false);
            }}
          >
            {t('beds.ratesSnapshot.close')}
          </Button>
        </div>
      </Grid>}

    </Grid>
  )
}

const mapStateToProps = (state) => ({
  ratePlans: state.ratePlans,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace)
})

export default withTranslation()(connect(mapStateToProps)(RatePlanRow));