import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";

import http from "../redux/utils/http";
import { logoutUser } from "../redux/actions/auth/auth";
import {
  loadSpace,
  setCurrentSpace,
  setCurrentModule,
} from "../redux/actions/dashboard/dashboard";
import { loadUserPermissions } from "../redux/actions/permissions/permissions";
import { loadTodos } from "../redux/actions/todos/todos";
import { loadAllFiles } from "../redux/actions/files/files";
import { loadNotifications } from "../redux/actions/notifications/notifications";
import { loadMessages } from "../redux/actions/messages/messages";
import { setSignupStep } from "../redux/actions/signup/signupCommunity";

import Header from "../components/header/HeaderNew";
import Sidebar from "../components/header/SidebarNew";
import DashboardContainer from "./DashboardContainer";
import Onboarding from "../components/onboarding/Onboarding";
import { MENU } from "../utils/layout";
import ChatSupport from "../components/chat-support/ChatSupport";
import AlertBanner from "../components/alert-banner/AlertBanner";

const styles = (theme) => ({
  rootMain: {
    display: "flex",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  root: {
    height: "100vh",
    flexGrow: 1,
    background: "#F8F9Fb",
    overflowY: "scroll",
    marginRight: "-17px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "unset",
    },
  },
  headerSpacer: {
    minHeight: "70px",
  },
  mobileSpacer: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "110px",
    },
  },
  communityHeader: {
    background: "#FFFFFF",
    boxShadow: "0 2px 20px #E0E0E0",
    position: "absolute",
    display: "flex",
    justifyContent: "flex-end",
    top: 0,
    right: 0,
    width: "calc(100vw - 80px)",
  },
  sidebar: {
    background: "#ffffff",
    borderRight: "0px solid #000000",
    height: "100vh",
  },
  teamsContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    right: 20,
    top: 0,
  },
  teams: {
    border: "0px solid #000000",
    borderRadius: 15,
    boxShadow: "0 2px 20px #E0E0E0",
    display: "block",
    overflow: "hidden",
    width: 294,
    zIndex: 1201,
    [theme.breakpoints.up("md")]: {
      width: 344,
    },
    [theme.breakpoints.up("lg")]: {
      width: 424,
    },
    [theme.breakpoints.up("xl")]: {
      width: 444,
    },
  },
  freePMS: {
    height: "calc(100vh - 140px)",
    marginTop: "140px",
  },
});

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banner: props.banner,
    };
  }
  componentDidMount = () => {
    // CHECK TOKEN - AUTHENTICATE
    // ------------------------------------------------
    if (this.props.auth.isAuthenticated) {
      const token = localStorage.jwtToken;
      http
        .get(`/api/user/checkTokenStatus/${token}`)
        .then((successResult) => {
          console.log("Successfully verified token");
        })
        .catch((errorResult) => {
          // console.log(errorResult )
          this.props.dispatch(logoutUser());
          this.props.history.push("/login");
        });
    }

    // FETCH DATA TO DISPLAY APP
    // ------------------------------------------------
    if (this.props.auth.user.migrationStep < 3) {
      // console.log('redirecting to signup')
      this.props.dispatch(setCurrentSpace(this.props.auth.user.space[0]));
      this.props.dispatch(setSignupStep(this.props.auth.user.migrationStep));
      this.props.history.push("/signup");
    } else {
      this.props.dispatch(
        loadUserPermissions(
          this.props.currentUser._id,
          this.props.currentSpaceID
        )
      );
      this.props.auth.user.space.map((space) => {
        this.props.dispatch(loadSpace(space, this.props.currentSpaceID));
        this.props.dispatch(loadTodos(space));
        this.props.dispatch(loadAllFiles(space));
        // this.props.dispatch(getChatPreview(space));
        // this.props.dispatch(loadAnnouncements(space));
      });
      this.props.dispatch(loadNotifications());
      this.props.dispatch(loadMessages());
    }

    //  GOOGLE TAG MANAGER
    // ------------------------------------------------
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userID:
        this.props.auth && this.props.auth.user && this.props.auth.user?._id,
      spaceID: this.props.currentSpace && this.props.currentSpace._id,
      onboardingStatus:
        this.props.currentSpace && this.props.currentSpace.onboardingStatus,
      spaceName: this.props.currentSpace && this.props.currentSpace.name,
      isAdmin:
        this.props.currentSpace &&
        this.props.currentSpace.admins &&
        this.props.currentSpace.admins.some(
          (item) => item === this.props.auth.user._id
        ),
    });

    //  SET PAGE URL
    // ------------------------------------------------
    let segments = window?.location?.pathname.split("/");
    let index1 = -1;
    if (segments[1] && segments[1] !== "")
      index1 = Object.keys(MENU).indexOf(segments[1]);
    let index2 = -1;
    if (index1 > -1 && segments[2] && segments[2] !== "")
      index2 = Object.keys(MENU[segments[1]].submenu).indexOf(segments[2]);
    if (index1 > -1) {
      if (
        index2 > -1 &&
        (!this.props.currentSubModule ||
          this.props.currentSubModule !== segments[2])
      ) {
        this.props.dispatch(setCurrentModule(segments[1], segments[2]));
      } else if (
        !this.props.currentModule ||
        this.props.currentModule !== segments[1]
      ) {
        this.props.dispatch(setCurrentModule(segments[1]));
        window.history.replaceState({}, document.title, `/${segments[1]}`);
      } else if (this.props.currentModule == segments[1] && index2 == -1) {
        window.history.replaceState({}, document.title, `/${segments[1]}`);
      }
    } else {
      // No URL match. Setting URL based on state.
      if (this.props.currentModule && this.props.currentModule !== "") {
        if (this.props.currentSubModule && this.props.currentSubModule !== "") {
          window.history.replaceState(
            {},
            document.title,
            `/${this.props.currentModule}/${this.props.currentSubModule}`
          );
        } else {
          window.history.replaceState(
            {},
            document.title,
            `/${this.props.currentModule}`
          );
        }
      }
    }
  };

  render() {
    const {
      classes,
      spaces,
      currentSpaceID,
      currentSpace,
      currentSpaceType,
      currentUser,
      match,
      location,
      onboarding,
      searchActive,
    } = this.props;

    const { banner } = this.state;

    const setBanner = (banner) => {
      this.setState({ ...this.state, banner });
    };

    const closeBanner = async (banner) => {
      setBanner(undefined);
    };

    let displayPMSProgress = false;
    if (spaces) {
      let hostelFound = Object.values(spaces).find(
        (item) => item.type !== "Association" || item.type !== "Community"
      );
      if (hostelFound && currentUser.migrationStep === 4)
        displayPMSProgress = true;
    }

    const user = {
      user_id: currentUser._id,
      email: currentUser.email,
      name: currentUser.name,
      migrationStep: currentUser.migrationStep,
      spaceID: currentSpace && currentSpace._id,
      propertyID: currentSpace && currentSpace.propertyID,
      propertyName: currentSpace && currentSpace.name,
      isAdmin:
        currentSpace &&
        currentSpace.admins &&
        currentSpace.admins.some((item) => item === currentUser._id),
      blacklist: currentUser && currentUser.blacklist,
    };

    return (
      <div className={classes.rootMain}>
        {banner && (
          <AlertBanner
            banner={banner}
            closeBanner={() => closeBanner(banner)}
          />
        )}
        {!searchActive && (
          <div className={classes.sidebar}>
            <Sidebar />
          </div>
        )}

        <div className={clsx(classes.root)}>
          <ChatSupport />

          <Header />

          <div
            className={clsx({
              [classes.headerSpacer]: true,
              [classes.mobileSpacer]:
                currentSpace &&
                (!currentSpace.onboardingStatus ||
                  currentSpace.onboardingStatus === "trial"),
            })}
          />

          {/* {!onboarding && <Hidden mdUp><Header /></Hidden>} */}

          {currentSpace && onboarding && <Onboarding />}

          {!onboarding &&
            currentSpaceType !== "Association" &&
            currentSpaceType !== "Community" && <DashboardContainer />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  banner: state.banners,
  onboarding: state.uiConfig.onboarding,
  currentUser: state.auth.user,
  currentModule: state.dashboard.currentModule,
  currentSubModule: state.dashboard.currentSubModule,
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentSpaceType: state.dashboard.currentSpaceType,
  mobileSidebarOpen: state.dashboard.mobileSidebarOpen,
  spaces: state.spaces,
  searchActive: state.search.searchActive,
});

export default withStyles(styles)(
  connect(mapStateToProps)(withRouter(AppContainer))
);
