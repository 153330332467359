import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles(theme =>({
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '60%'
  },
  description: {
    height: 80,
  }
}));

const BasicInfo = props =>{
    const classes = useStyles();
    const { 
        ratePlan,
        handleUpdateRatePlan
    } = props;

    return(
        <>
            <div className="main-content">
                
                <div className="main-content-title">Basic Information</div>
                <div className="sub-title">Describe this rate plan for private and public use </div>

                <div className="row">
                  <div className="label">Public Name</div>
                  <div className={classes.field}>
                    <InputBase 
                      id="publicName"
                      name="publicName"
                      value={ratePlan.publicName}
                      fullWidth
                      type="text"
                      autoFocus
                      onChange={(e) => handleUpdateRatePlan('publicName', e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="label">Private Name</div>
                  <div className={classes.field}>
                    <InputBase 
                      id="privateName"
                      name="privateName"
                      fullWidth
                      value={ratePlan.privateName}
                      type="text"
                      onChange={(e) => handleUpdateRatePlan('privateName', e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="label">Description</div>
                  <div className={clsx(classes.field, classes.description)}>
                    <InputBase 
                      id="description"
                      name="description"
                      fullWidth
                      value={ratePlan.description}
                      type="text"
                      multiline
                      rows={3}
                      onChange={(e) => handleUpdateRatePlan('description', e.target.value)}
                    />
                  </div>
                </div>

            </div>
        </>
    )
}

export default BasicInfo; 