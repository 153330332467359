import { IMAGES_CDN_PATH } from "../../config";

const imagePath = IMAGES_CDN_PATH  + "/icons";
const layoutImagePath = IMAGES_CDN_PATH + "/layout";
const weatherImagePath = IMAGES_CDN_PATH + "/weather";
const placeholderImagePath = IMAGES_CDN_PATH + "/placeholders";
const experiencesImagePath = IMAGES_CDN_PATH + "/experiences";
const animationsImagePath = IMAGES_CDN_PATH + "/animations";

export default {
  //Common......................
  darkAdd: imagePath + "/dark-add.png",
  pencil: imagePath + "/pencil.svg",
  darkMinus: imagePath + "/dark-minus.png",
  camera: imagePath + "/camera.png",
  location: imagePath + "/location.png",
  bin: imagePath + "/bin.png",
  posPlaceholder: placeholderImagePath + "/pos-placeholder.png",
  darkClose: imagePath + "/dark-close.png",
  bulb: imagePath + "/bulb.png",
  image: imagePath + "/image.svg",
  arrows: imagePath + "/arrows.png",
  editPencil: imagePath + "/edit-pencil.png",
  rooms: imagePath + "/rooms.png",
  correct: imagePath + "/correct.svg",
  check: imagePath + "/check.png",
  user: imagePath + "/user.png",
  private: imagePath + "/private.png",

  //MAIN HEADER...................
  message: imagePath + "/message.png",
  notification: imagePath + "/notification.png",
  settings: imagePath + "/settings.png",

  //SIDEBAR.............
  live: layoutImagePath + "/live.png",
  liveSelected: layoutImagePath + "/live-selected.png",

  bed: layoutImagePath + "/bed.png",
  bedSelected: layoutImagePath + "/bed-selected.png",

  experiences: layoutImagePath + "/experiences.png",
  experiencesSelected: layoutImagePath + "/experiences-selected.png",

  pos: layoutImagePath + "/pos.png",
  posSelected: layoutImagePath + "/pos-selected.png",

  team: layoutImagePath + "/team.png",
  teamSelected: layoutImagePath + "/team-selected.png",

  enter: imagePath + "/enter.svg",
  copy: imagePath + "/copy.svg",

  //WEATHER ICONS................
  clear: weatherImagePath + "/clear.png",
  clouds: weatherImagePath + "/clouds.png",
  drizzle: weatherImagePath + "/drizzle.png",
  other: weatherImagePath + "/other.png",
  rain: weatherImagePath + "/rain.png",
  snow: weatherImagePath + "/snow.png",
  thunderstorm: weatherImagePath + "/thunderstorm.png",

  //EDIT RESERVATION................
  //header-------------------------
  logout: imagePath + "/logout.svg",
  trash: imagePath + "/trash.svg",
  cart: imagePath + "/cart.svg",
  shield: imagePath + "/shield.svg",

  //reservation status-------------------
  revert: imagePath + "/revert.svg",
  checkIn: imagePath + "/check-in.svg",
  checkOut: imagePath + "/check-out.svg",

  //stay info----------------------
  darkBoyBroadSmile: imagePath + "/dark-boy-broad-smile.png",
  darkSleep: imagePath + "/dark-sleep.png",

  //AccomodationCard------------------
  dateArrow: imagePath + "/dateArrow.png",
  information: imagePath + "/information.svg",
  shuffle: imagePath + "/shuffle.png",
  dollar: imagePath + "/dollar.png",
  fourSquaresViewOptions:
    imagePath + "/four-squares-button-of-view-options.png",
  square: imagePath + "/square.png",
  lock: imagePath + "/lock.svg",
  unlock: imagePath + "/unlock.svg",

  //Upsell tab-------------------------
  upsellCleaning: imagePath + "/upsell-cleaning.png",
  upsellCleaningWhite: imagePath + "/upsell-cleaning-white.png",
  upsellLocks: imagePath + "/upsell-locks.png",
  upsellLocksWhite: imagePath + "/upsell-locks-white.png",
  upsellTowel: imagePath + "/upsell-towel.png",
  upsellTowelWhite: imagePath + "/upsell-towel-white.png",
  upsellBeachTowel: imagePath + "/upsell-beach-towel.png",
  upsellBeachTowelWhite: imagePath + "/upsell-beach-towel-white.png",
  upsellSheets: imagePath + "/upsell-sheets.png",
  upsellSheetsWhite: imagePath + "/upsell-sheets-white.png",
  upsellTp: imagePath + "/upsell-tp.png",
  upsellTpWhite: imagePath + "/upsell-tp-white.png",
  upsellGiftShop: imagePath + "/upsell-gift-shop.png",
  upsellGiftShopWhite: imagePath + "/upsell-gift-shop-white.png",
  upsellMap: imagePath + "/upsell-map.png",
  upsellMapWhite: imagePath + "/upsell-map-white.png",
  upsellDrink: imagePath + "/upsell-drink.png",
  upsellDrinkWhite: imagePath + "/upsell-drink-white.png",
  upsellCoffee: imagePath + "/upsell-coffee.png",
  upsellCoffeeWhite: imagePath + "/upsell-coffee-white.png",
  upsellFood: imagePath + "/upsell-food.png",
  upsellFoodWhite: imagePath + "/upsell-food-white.png",
  upsellWater: imagePath + "/upsell-water.png",
  upsellWaterWhite: imagePath + "/upsell-water-white.png",
  upsellPickUp: imagePath + "/upsell-pick-up.png",
  upsellPickUpWhite: imagePath + "/upsell-pick-up-white.png",
  upsellMisc: imagePath + "/upsell-misc.png",
  upsellMiscWhite: imagePath + "/upsell-misc-white.png",

  //Summary tab------------------
  plane: imagePath + "/plane.svg",
  invoice: imagePath + "/invoice.png",
  discounts: imagePath + "/discounts.png",
  taxes: imagePath + "/taxes.png",
  refunds: imagePath + "/refunds.png",
  view: imagePath + "/view.png",
  visibilityHidden: imagePath + "/visibility-hidden.png",
  cash: imagePath + "/cash.png",
  card: imagePath + "/card.png",
  stripe: imagePath + "/stripe.png",

  //RESERVATION CALENDAR...................
  sort: imagePath + "/sort.png",
  block: imagePath + "/block.png",
  palette: imagePath + "/palette.png",
  fullscreen: imagePath + "/fullscreen.png",
  search2: imagePath + "/search2.png",
  list: imagePath + "/list.png",
  tiles: imagePath + "/tiles.png",

  //BEDS AUTOMATE.............
  demo: imagePath + "/demo.png",
  learnMore: imagePath + "/learn-more.png",

  //ROOM BLOCKS....................................
  darkBulb: imagePath + "/dark-bulb.png",
  darkFlower: imagePath + "/dark-flower.png",
  darkHammock: imagePath + "/dark-hammock.png",
  darkSupport: imagePath + "/dark-support.png",
  darkMore: imagePath + "/dark-more.png",
  darkBed: imagePath + "/dark-bed.png",
  darkHostel: imagePath + "/dark-hostel.png",
  privateRoomType: placeholderImagePath + "/PrivateRoomType.png",
  dormRoomType: placeholderImagePath + "/DormRoomType.png",

  //NEW RESERVATION.........................
  darkCall: imagePath + "/dark-call.png",
  darkEmail: imagePath + "/dark-email.png",
  darkFootprint: imagePath + "/dark-footprint.png",
  darkCalendar: imagePath + "/dark-calendar.png",

  //TAXES.........
  nights: imagePath + "/nights.png",
  guest: imagePath + "/guest.png",
  discount: imagePath + "/discount.png",
  calendar2: imagePath + "/calendar2.png",

  //EXPERIENCES.................
  undo: imagePath + "/undo.svg",
  home: imagePath + "/home.svg",
  experienceMin: experiencesImagePath + "/experience-min.png",
  experienceMax: experiencesImagePath + "/experience-max.png",
  experienceInstructor: experiencesImagePath + "/experience-instructor.png",
  experienceLocation: experiencesImagePath + "/experience-location.png",
  experienceUser: experiencesImagePath + "/experience-user.png",
  experienceSource: experiencesImagePath + "/experience-source.png",
  experienceDefault: experiencesImagePath + "/experience-default.png",

  folderIcon: imagePath + "/folder-icon.png",
  hostel: placeholderImagePath + "/hostel.jpg",

  //CHANNELS..............
  email: imagePath + "/email.png",
  whatsapp: imagePath + "/whatsapp.png",
  mobilePhone: imagePath + "/mobile-phone.png",
  idea: imagePath + "/idea.png",
  question: imagePath + "/question.svg",

  //ANIMATIONS...............................
  bookingComLoading: animationsImagePath + "/booking-com-loading.gif",

  //HW INBOX MIGRATION...........................
  stepperUser: imagePath + "/stepper-user.svg",
  stepperHostel: imagePath + "/stepper-hostel.svg",
  stepperRooms: imagePath + "/stepper-rooms.svg",
  stepperChannels: imagePath + "/stepper-channels.svg",
  stepperPayment: imagePath + "/stepper-payment.svg",
};
