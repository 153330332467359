import React, { useEffect } from 'react';
import { makeStyles, CircularProgress, formatMs, Switch, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import { updateSettings } from '../../redux/actions/onlineCheckin/onlineCheckin';

import TrixEditor from '../utils/TrixEditor';

import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles( theme => ({
  divider: {
    marginTop: 20,
    marginBottom: 20
  },
  row: {
  	display: 'flex',
  	flexDirection: 'column',
  	marginBottom: 30
  },
  title: {
  	fontSize: '1.5rem',
  	fontWeight: 600,
  	lineHeight: 1.3
  },
  labelTitle: {
  	fontSize: '1.4rem',
  	color: '#666666',
    fontWeight: 600,
  	marginTop: 20
  },
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 400,
    marginTop: 10
  },
  toggle: {
  	alignItems: 'center',
  	boxShadow: '0 1px 20px #F2F2F2',
  	borderRadius: 25,
  	display: 'flex'
  },
  toggleButton: {
  	alignItems: 'center',
  	color: '#BDBDBD',
  	cursor: 'pointer',
  	display: 'flex',
  	fontSize: '1.2rem',
  	fontWeight: 600,
  	height: 35,
  	justifyContent: 'center',
  	width: '50%'
  },
  left: {
  	borderTopLeftRadius: 25,
  	borderBottomLeftRadius: 25
  },
  right: {
  	borderTopRightRadius: 25,
  	borderBottomRightRadius: 25
  },
  selected: {
  	background: '#4F9581',
  	boxShadow: '-15px 0px 17px -7px #F2F2F2',
  	color: '#FFFFFF'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10
  },
  button: {
    background: '#000000',
    borderRadius: 25,
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1.25rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  },
  switchContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  errorMessage: {
    color: "#f44336",
    fontSize: 12
  }
}))

const CustomSwitch = withStyles({
  switchBase: {
    color: '#E0E0E0',
    '&$checked': {
      color: '#8CB2A4',
    },
    '&$checked + $track': {
      backgroundColor: '#8CB2A4',
    },
  },
  checked: {},
  track: {},
})(Switch)

const CustomerRules = (props) => {
  const { t } = props
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const classes = useStyles()
  const { open, handleClose, currentSpace, property, loadingProperty, isLoading, onlineCheckin } = props
  const [toggle, setToggle] = React.useState(false)
  const [initial, setInitial] = React.useState(true)
  const [status, setStatus] = React.useState({rules: false, terms: false, covid: false})
  const [body, setBody] = React.useState({rules: '', terms: '', covid: ''})
  const [error, setError] = React.useState({rules: false, terms: false, covid: false})
  const [message, setMessage] = React.useState('')

  if (onlineCheckin.customerRules && initial) {
    setInitial(false)
    setToggle(!onlineCheckin.customerRules.skip)

    const info = {
      rules: ((onlineCheckin.customerRules.houseRules) && (onlineCheckin.customerRules.houseRules.length > 0)) ? true : false,
      terms: ((onlineCheckin.customerRules.termsAndConditions) && (onlineCheckin.customerRules.termsAndConditions.length > 0)) ? true : false,
      covid: ((onlineCheckin.customerRules.covidGuidelines) && (onlineCheckin.customerRules.covidGuidelines.length > 0)) ? true : false
    }

    setStatus(info)

    const data = {
      rules: onlineCheckin.customerRules.houseRules,
      terms: onlineCheckin.customerRules.termsAndConditions,
      covid: onlineCheckin.customerRules.covidGuidelines
    }

    setBody(data)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleToggle = (value) => {
    if((toggle) && (!value)) {
      const data = {
        skip: !value
      }

      props.dispatch(updateSettings(currentSpace.propertyID, 'customerRules', data))
    }
    setToggle(value)
  }

  const handleChange = (value) => {
    if(status[value]) {
      setBody({...body, [value]: ''})
    }
    setStatus({...status, [value]: !status[value]})
    setError({...error, [value]: false})
  }

  const handleUpdate = (update, type) => {
    setBody({...body, [type]: update})
    if(error[type]) {
      setError({ ...error, [type]: false})
    }
  }

  const trueCheck = (obj) => {
    let i = 0
    for (var o in obj) {
      if(obj[o]) {
        i = i + 1
      }
    }

    if(i > 0)
      return true
    else
      return false
  }

  const handleSave = () => {
    const data = {
      skip: !toggle,
      houseRules: body.rules,
      termsAndConditions: body.terms,
      covidGuidelines: body.covid
    }

    if(status.rules) {
      if(body.rules.length === 0) {
        return setError({ ...error, rules: true})
      }
    }

    if(status.terms) {
      if(body.terms.length === 0) {
        return setError({ ...error, terms: true})
      }
    }

    if(status.covid) {
      if(body.covid.length === 0) {
        return setError({ ...error, covid: true})
      }
    }

    props.dispatch(updateSettings(currentSpace.propertyID, 'customerRules', data))
    .then((response) => {
      if(response && response.status === 200) {
        setMessage('Customer Rules settings have been saved.')
        setOpenSnackbar(true)
        setSave(false)
      }
      else {
        setMessage('Your settings has not been saved. Please try again!')
        setOpenSnackbar(true)
      }
    })
  }

  return (
    <div>
      <div className={classes.row}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <div className={classes.title}>
              {t('automate.onlineCheckin.steps.customerRules.name')}
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.toggle}>
              <div 
                className={clsx(classes.toggleButton, classes.left)} 
                style={{background: toggle===false ? '#F2F2F2' : '#FFFFFF'}}
                onClick={() => handleToggle(false)}
              >
                OFF
              </div>
              <div 
                className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.selected]: toggle===true})}
                onClick={() => handleToggle(true)}
              >
                ON
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {(toggle) && <div className={classes.row}>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <p className={classes.labelTitle}>{t('automate.onlineCheckin.steps.customerRules.houseRules')}</p>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.switchContainer}>
              <CustomSwitch
                checked={status.rules}
                onChange={() => handleChange('rules')}
                color="primary"
                name="switch"
                inputProps={{ 'aria-label': 'room mapping switch' }}
              />
            </div>
          </Grid>
        </Grid>
        {(status.rules) && <TrixEditor
          placeholder=""
          submitOnEnter={false}
          enableOnChange={true}
          initialValue={body.rules}
          handleSlateOnChange={(e) => handleUpdate(e, 'rules')}
        />}
        {(error.rules) && <span className={classes.errorMessage}>Please enter the rules before saving</span>}
        <Divider className={classes.divider}/>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <p className={classes.labelTitle}>{t('automate.onlineCheckin.steps.customerRules.termsAndConditions')}</p>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.switchContainer}>
              <CustomSwitch
                checked={status.terms}
                onChange={() => handleChange('terms')}
                color="primary"
                name="switch"
                inputProps={{ 'aria-label': 'room mapping switch' }}
              />
            </div>
          </Grid>
        </Grid>
        {(status.terms) && <TrixEditor
          placeholder=""
          submitOnEnter={false}
          enableOnChange={true}
          initialValue={body.terms}
          handleSlateOnChange={(e) => handleUpdate(e, 'terms')}
        />}
        {(error.terms) && <span className={classes.errorMessage}>Please enter the terms and conditions before saving</span>}
        <Divider className={classes.divider}/>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <p className={classes.labelTitle}>{t('automate.onlineCheckin.steps.customerRules.covid19Guidelines')}</p>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.switchContainer}>
              <CustomSwitch
                checked={status.covid}
                onChange={() => handleChange('covid')}
                color="primary"
                name="switch"
                inputProps={{ 'aria-label': 'room mapping switch' }}
              />
            </div>
          </Grid>
        </Grid>
        {(status.covid) && <TrixEditor
          placeholder=""
          submitOnEnter={false}
          enableOnChange={true}
          initialValue={body.covid}
          handleSlateOnChange={(e) => handleUpdate(e, 'covid')}
        />}
        {(error.covid) && <span className={classes.errorMessage}>Please enter the Covid 19 guidelines before saving</span>}
      </div>}
      {(trueCheck(status)) && (toggle) && <div className={classes.buttonsContainer}>
        <div className={classes.button} onClick={handleSave}>
          Save
        </div>
      </div>}
      {(message) && <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key='top-center'
        autoHideDuration={3000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />}
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  isLoading: state.loading.EDIT_PROPERTY,
  loadingProperty: state.loading.LOAD_PROPERTY,
  onlineCheckin: state.onlineCheckin
})

export default withTranslation()(connect(mapStateToProps)(CustomerRules))