import React, { useEffect } from "react";
import {
  makeStyles,
  withStyles,
  CircularProgress,
  formatMs,
  Switch,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import {
  fetchProperty,
  editProperty,
  resetError,
} from "../../redux/actions/dashboard/property";
import {
  getBookingEngineSettings,
  saveBookingEngineSettings,
} from "../../redux/actions/bookingEngine/bookingEngine";
import TrixEditor from "../utils/TrixEditor";

import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Checkbox from "@material-ui/core/Checkbox";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  label: {
    color: "#000000",
    fontSize: "1.2rem",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  labelTitle: {
    fontSize: "1.4rem",
    color: "#666666",
    fontWeight: 600,
    marginTop: 20,
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  startContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 50,
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.4rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const CustomSwitch = withStyles({
  switchBase: {
    color: "#E0E0E0",
    "&$checked": {
      color: "#8CB2A4",
    },
    "&$checked + $track": {
      backgroundColor: "#8CB2A4",
    },
  },
  checked: {},
  track: {},
})(Switch);

const TermsConditions = (props) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const { t } = props;
  const {
    open,
    handleClose,
    currentSpace,
    property,
    bookingEngine,
    GA,
    FB,
    loadingProperty,
    errors,
    isLoading,
    stripe,
  } = props;
  const [edit, setEdit] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [body, setBody] = React.useState("");
  const [status, setStatus] = React.useState({
    terms: false,
  });

  useEffect(() => {
    if (currentSpace && currentSpace.propertyID) {
      props
        .dispatch(getBookingEngineSettings(currentSpace.propertyID))
        .then((res) => {
          let terms = "";
          if (res) {
            terms = res.terms ? res.terms : "";
            setBody(terms);
          }
        });
    }
  }, [bookingEngine]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleChange = (value) => {
    if (status[value]) {
      setBody("");
    }
    setStatus({ ...status, [value]: !status[value] });
  };

  const handleUpdate = (update) => {
    setBody(update);
    if (!edit) {
      setEdit(true);
    }
  };

  const handleSave = () => {
    props.dispatch(
      saveBookingEngineSettings(currentSpace.propertyID, { terms: body })
    );
    setEdit(false);
  };

  return (
    <div className={classes.row}>
      <div className={classes.linkContainer}>
        <div className={classes.startContainer}>
          <p className={classes.labelTitle}>Terms and Conditions</p>
        </div>
      </div>
      {!body.length && (
        <TrixEditor
          placeholder="Your terms and conditions go here ..."
          submitOnEnter={false}
          enableOnChange={true}
          initialValue={""}
          handleSlateOnChange={(e) => handleUpdate(e)}
        />
      )}
      {body && (
        <TrixEditor
          placeholder="Your terms and conditions go here ..."
          submitOnEnter={false}
          enableOnChange={true}
          initialValue={body}
          handleSlateOnChange={(e) => handleUpdate(e)}
        />
      )}
      {edit && (
        <div className={classes.buttonsContainer}>
          <div className={classes.button} onClick={handleSave}>
            Save
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  bookingEngine: state.property.bookingEngineURL,
  stripe: state.stripe,
  GA: state.property.googleAnalyticsID,
  FB: state.property.fbPixelID,
  loadingProperty: state.loading.LOAD_PROPERTY,
  isLoading:
    state.loading.EDIT_PROPERTY ||
    state.loading.GET_BOOKING_ENGINE_SETTINGS ||
    state.loading.SAVE_BOOKING_ENGINE_SETTINGS,
  errors: state.errors.EDIT_PROPERTY,
});

export default withTranslation()(connect(mapStateToProps)(TermsConditions));
